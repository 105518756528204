@media all and (max-width: 768px) {
    .mobile-menu {
      .main-menu {
        display: none;
        // padding-bottom: 42px;
      }
    }

  .mobile-menu-bar {
    display: block;
  }

  #main-menu {
    display: none;
  }

  .region-search {
    h2.home-block-title {
      margin-top: 0;
      font-size: #{"calc(26px + 3vw)"};
    }

    #block-search-form {

      padding: 0;

      form.form-search .input-group {
        width: 100%;
      }
    }

  }

  .boats-wrapper {

    .swiper-button-next {
      right: 15px;
    }
    .swiper-button-prev {
      left: 15px;
    }

    .swiper-button-next:before,
    .swiper-button-prev:before {
      height: 0;
    }
  }
}

@media all and (max-width: 480px) {

  .boats-wrapper {

    .swiper-button-prev {
      left: 5px;
    }
    .swiper-button-next {
      right: 5px;
    }

    .swiper-button-prev,
    .swiper-button-next {
      border: none;
      font-size: 60px;
      top: 50%;
      color: #00bff3;
      width: auto;
      height: auto;
      opacity: 0.5;
    }

    .swiper-button-prev:hover,
    .swiper-button-next:hover {
      opacity: 1;
    }

  }

}