body *:focus {
  outline: none;
}

input[type="checkbox"] {
  line-height: 1;
  height: auto;
  width: auto;
  display: inline;
}

.bef-tree {
  list-style: none;
  padding: 0;

  li {
    list-style: none;
  }
}

.views-exposed-widgets {
  display: flex;
  flex-wrap: wrap;

  & > .views-exposed-widget {
    flex-basis: 25%;

    &.views-submit-button {
      flex-basis: 100%;
    }
  }
}
div.form-control {
  height: auto;
  background: none;
  border: none;
}

.view-search-boats .views-row {
  margin-bottom: 20px;
  padding: 20px;
  background: $white;
  box-sizing: border-box;
}

.form-item .price {
  background: $white;
  padding: 5px 10px;
  font-weight: bold;
}

.term-reference-tree ul li {
  list-style: none;
  padding: 2px 0;
}

.alert-booking {
  display: none;
}
fieldset.date-combo .container-inline-date .date-padding,
.field-type-datetime .container-inline-date {
  width: auto;
  display: inline-block;
}
.container-inline-date .date-padding {
  float: none;
}

/* Boat Cards*/
.boat-card {
  display: flex;

  .boat-card-col + .boat-card-col {
    margin-left: 20px;
  }
  .boat-card-col:nth-child(2) {
    padding-right: 20px;
    border-right: 1px dashed #ccc;
  }

  .boat-title a {
    font-size: 130%;
    font-weight: bold;
    color: #000;
  }

}

.option-price {
  margin-left: 5px;
}

/*Hide trimmed on preview*/
.page-node-add-booking {
  .preview {
    .node-teaser,
    & > h3 {
      display: none;
    }
  }
  .node-preview {
    padding: 25px;

    & > header {
      display: none;
    }
  }
}

.hide-subtree {
  border: none;
  margin-right: 5px;
  background: none;

  &:focus {
    outline: none;
  }
}

#edit-field-boat-price-options-tid-wrapper ul.bef-tree-child {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 1px;
    background: #ccc;
    height: 100%;
    top: -6px;
    left: 12px;
    z-index: 1;
  }
}

.chosen-results .highlighted {
  color: $white !important;
}

.bef-tree .form-type-bef-checkbox {
  white-space: nowrap;
}
.view-view-full-width-for-boat-page {
  .views-field-field-boat-images img {
    max-width: none;
    width: 100%;
    height: 42vw;
    object-fit: cover;
    font-family: 'object-fit: cover;';
  }
}
#edit-field-boat-beds-count-value-wrapper {

  & > .views-widget {
    display: flex;

    .form-type-textfield {
      display: flex;
      align-items: center;
    }
  }

  .form-item-field-boat-beds-count-value-max .control-label {
    margin: 0 5px;
  }

  input[type="text"] {
    max-width: 50px;
    padding: 0 5px;
  }
}

#edit-field-boat-languages-spoken-value-wrapper {
  .bef-select-as-checkboxes {
    border: none;
    box-shadow: none;
  }
}

/* Map latlng Widget*/
#edit-field-coordinates-distance-wrapper {
  position: relative;
}

.field-group-tab:not(.active) {
  display: none !important;
}

.boat-actions {
  margin-bottom: 15px;
}

/*#content-top-first-wrapper {
  display: none;
}*/
.home-block-title strong {
  text-shadow: 1px 1px 2px #555, -1px -1px 2px #555;
}

.home-block-description {
  text-shadow: -3px -3px 5px #000, 3px -3px 5px #000, 3px 3px 5px #000, -3px 3px 5px #000;
}

.btn-default.disabled,
.btn-default[disabled] {
  color: #ccc;
}

// Language switcher
#header-region #block-locale-language {
  margin-bottom: 10px;
}
#header-region .language-switcher-locale-url {
  list-style: none;
  padding-left: 0;
  margin: 0;
  overflow: hidden;
}
#header-region .language-switcher-locale-url li {
  font-size: 12px;
  line-height: 1.5;
  list-style-type: none;
  float: left;
  margin-left: 14px;
}
#header-region .language-switcher-locale-url li:first-child {
  margin-left: 0;
}
#header-region .language-switcher-locale-url li img {
  display: inline;
  position: relative;
  top: -2px;
}

@media (min-width: 992px) {
  #header-region #block-locale-language {
    float: right;
    margin-right: 22px;
    margin-bottom: 0;
    position: relative;
    top: 20px;
    z-index: 1000;
  }
  #header-region .language-switcher-locale-url li a {
    display: inline-block;
  }
}

.mobile-menu-bar ul.menu li.expanded ul {
  background-color: #0c1923;
  border-top: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.mobile-menu-bar .nav .open > a,
.mobile-menu-bar .nav .open > a:hover,
.mobile-menu-bar .nav .open > a:focus {
  background-color: #0c1923;
}

.mobile-menu-bar .mobile-menu .main-menu li a {
  padding: 15px 20px !important;
}

#views-exposed-form-search-boats-page h3:first-child {
  margin-top: 30px;
}
#views-exposed-form-search-boats-page h3:first-child {
  margin-top: 7px;
}
#views-exposed-form-search-boats-page .form-item-field-coordinates-distance-search-distance {
  margin-bottom: 1em;
}

#views-exposed-form-search-boats-page .form-item-field-boat-price-value,
#views-exposed-form-search-boats-page .form-item-field-boat-beds-count-value,
#views-exposed-form-search-boats-page .form-item-field-boat-languages-spoken-value,
#views-exposed-form-search-boats-page .form-item-title {
  margin-bottom: 1em;
}

// User login form
@media (min-width: 992px) {
  #user-login {
    width: 50%;
  }
}

// HybridAuth
.hybridauth-widget-wrapper h3 {
  font-size: 19px;
  border-top: 1px solid #dfdfdf;
  padding-top: 10px;
}

// Contact page
.page-contact h1.page-title {
  display: block;
  text-transform: none;
}
.page-contact #block-block-6 p {
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .page-contact #content-wrapper {
    padding: 0;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .page-contact #content-wrapper {
    padding: 25px 0 !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .page-contact #content-wrapper {
    padding: 25px 0 !important;
  }
}

@media (min-width: 992px) {
  #contact-site-form {
    margin-top: 20px;
  }
}

// Boat booking page
.page-node-add-booking {
  background-color: $white;

  .page-title {
    text-transform: none;
    margin-bottom: 15px
  }

  .tabledrag-toggle-weight-wrapper {
    display: none;
  }

  label[for="edit-field-date-start-und-0-value2"] {
    position: relative;
    top: -20px;
  }

  #field-names-of-guests-values {
    border: none;
  }

  .field-name-field-booking-rendezvous-info {
    font-size: 85%;
    font-style: italic;
  }

  .form-item-field-booking-rendezvous-info-und  {
    font-size: 14px;
    font-style: normal;
  }

  #boat-image-wrapper {
    margin-bottom: 15px;
  }
  #boat-image-wrapper img {
    display: block;
    max-width: 100%;
    height: auto;
    width: 100%;
  }
  #boat-image-wrapper + h1 {
    font-size: 23px;
    text-transform: none;
  }

  .availability_calendar + h2 {
    font-size: 23px;
  }

  span.price {
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    padding: 3px 8px;
  }

  textarea {
    padding: 6px 12px;
  }

  .radio label,
  .checkbox label {
    margin-right: 0.5em;
  }

  a.tabledrag-handle .handle,
  form .field-multiple-table td.field-multiple-drag {
    display: none;
  }

  .table > thead > tr > th,
  .table > tbody > tr > th,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > tbody > tr > td,
  .table > tfoot > tr > td {
    padding: 0;
  }

  form .field-multiple-table {
    border: 0;
  }

  .draggable .form-group {
    margin-bottom: 7px;
  }

  #edit-field-names-of-guests-und-0-value {
    border-top: 0;
  }
}

@media (min-width: 992px) {
  .page-node-add-booking {
    .ds-form {
      padding-bottom: 40px;
    }
  }
}

#booking-node-form .availability_calendar .fc-center h2 {
  font-size: 19px;
  line-height: 1.6;
}

.fc-row .fc-content-skeleton {
     min-height: 84px !important;
}

.connect-with-biz a {
  color: rgb(153,153,153) !important;
}
.connect-with-biz a:hover {
  color: $white !important;
}

#pac-input {
  width: auto;
}

#block-block-10 {
  display: none;
}
#block-block-10 p {
  font-size: 19px;
  margin-top: 1em;
}
.page-toboggan-denied #block-block-10 p {
  margin-bottom: 20px;
}
.page-toboggan-denied #block-block-10 {
  margin-bottom: 0;
}
.page-user-login #block-block-10,
.page-toboggan-denied #block-block-10 {
  display: block
}


.rtecenter {
  text-align: center;
}
#search-wrapper {
  background-size: cover;
}
.image-background-second-wrapper {
  background: url(/sites/all/themes/bob_theme/img/image-background-2-region.jpg) center center repeat-y;
}

/* Newsletters */
.block.block-bobsubscribe {
  margin: 50px 0;
}
.block-bobsubscribe p {
  text-align: center;
  color: #fff;
  font-size: 180%;
}
.bobsubscribe-newsletter-signup-form {
  position: relative;
  text-align: center;
}
.block-bobsubscribe label {
  /*display: none;*/
  color: white;
}
.block-bobsubscribe .form-item.radio {
  display: inline-block;
  margin: 0 15px;
  vertical-align: middle;
}
.block-bobsubscribe .radio input[type="radio"] {
  margin-top: 4px;
}
.block-bobsubscribe .bobsubscribe-newsletter-signup-submit {
  border: none;
}
.not-logged-in .block-bobsubscribe .bobsubscribe-newsletter-signup-submit {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.block-bobsubscribe .bobsubscribe-newsletter-signup-submit,
.block-bobsubscribe #edit-mail {
  height: 50px;
}

.view-view-full-width-for-boat-page .views-field-field-boat-images img {
  height: 380px;
}

@media (min-width: 992px) {
  .bobsubscribe-newsletter-signup-form {
    width: 70%;
  margin-left: 15%;
  }
}

.block-bobsubscribe .bobsubscribe-newsletter-signup-submit {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.region-search h2.home-block-title {
  font-size: 50px;
  margin-top: 60px;
}

#block-search-form {
  padding: 60px 0px;

  form.form-search {
    padding: 20px 0;

    .input-group {
      width: 760px;
      margin: 0 auto;

      input {
        height: 50px;
        line-height: 50px;
        padding-left: 25px;
        border-radius: 5px 0 0 5px;
      }

      .input-group-btn {
        width: 115px;
        button {
          border-radius: 0 5px 5px 0;
          width: 115px;
          height: 50px;
        }
      }
    }

  }
}

.home-block-description {
  font-size: 120%;
  padding-bottom: 0px;
  text-align: center;
}

.view-view-full-width-for-boat-page:before {
  content: "";
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  height: 40%;
  width: 100%;
  background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.75) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.75) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.75) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.node-type-boat {
  .page-title {
    display: none;
  }
  #main-content {
    padding-top: 15px;
  }
  .field-group-htabs-wrapper {
    .horizontal-tabs {
      border: none;


      .group-main-info {
        border-radius: 0;
        margin: 0 0;
      }

      .field-label {
        padding: 10px 0;
        font-weight: bold;
        font-size: 120%;
        color: #000;
        text-transform: uppercase;
      }

      .field-name-field-boat-price-options {
        position: relative;
        .field-item:before {
          position: absolute;
          left: 0;
          content: "\f00c";
          font-family: FontAwesome;
          color: #00bff3;
          font-weight: normal;
        }
        .field-item {
          padding-left: 25px;
        }
      }


      .horizontal-tabs-list {
        display: block;

        .horizontal-tab-button {
          list-style: none;
        }
      }
    }
  }
}

.view-view-full-width-for-boat-page {
  position: relative;
  overflow: hidden;
  padding-bottom: 20px;

  .views-field-field-boat-images {
    /*display: none;*/
    position: absolute;
    left: 0; right: 0;
  }

  .views-field-nothing  {
    position: relative;
    padding-bottom: 15px;
    padding-top: 20px;
/*    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;*/

    .booking-controls-container {
      margin-left: 15px;
      margin-right: 15px;
    }

    .row {
      position: relative;
      z-index: 3;
    }

    .field-title {
      font-size: 250%;
      font-weight: bold;
      margin-bottom: 20px;
      margin-left: 30px;
      line-height: 1.2;
      color: #fff;
      text-shadow: #2B2B2B 1px 1px 2px;
    }

    .views-field-field-boat-name-of-owner {
      font-size: 120%;
      padding-bottom: 15px;
      margin-left: 30px;
      color: white;
      text-shadow: #666 1px 1px 1px;
      display: flex;

      .field-content {
        margin-left: 10px;
      }
    }

    .book-detail {
      background: #00bff3;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      border-radius: 30px;
      margin-right: 15px;
      margin-top: 20px;
      float: right;

      .field-book-boat {
        padding: 5px 5px 5px 5px;

        a {
          color: #fff;
          font-weight: bold;
          padding: 22px 25px 22px 0;
          i {
            margin-right: 15px;
            background: #286090;
            color: #fff;
            font-size: 120%;
            height: 50px;
            line-height: 50px;
            width: 50px;
            text-align: center;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
          }
        }
      }
    }

  }
}

.boats-wrapper {

  .views-row {
    padding-bottom: 20px;
    border: 1px solid #f2f2f2;
    background: #fff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    overflow: hidden;
    height: auto;
    box-sizing: border-box;

    .views-field-field-boat-name-of-owner {
      padding: 0 20px;
      box-sizing: border-box;
      display: flex;

      .field-content {
        margin-left: 10px;
      }
    }
    .views-field-title {
      padding: 10px 20px;
      box-sizing: border-box;

      a {
        color: #2b2b2b;
        font-size: 120%;
        font-weight: bold;
      }
      a:hover {
      color: #ff9900;
    }
    }
  }
  .swiper-button-next:before, .swiper-button-prev:before {
    content: "";
    background: #aaa;
    height: 1px;
    position: absolute;
    top: 50%;
    width: 150px;
  }
  .swiper-button-next:before {
    right: 40px;
  }
  .swiper-button-prev:before {
    left: 40px;
  }

  .swiper-button-next, .swiper-button-prev {
    font-size: 150%;
    color: #aaa;
    text-align: center;
    z-index: 1;
    top: 28px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border: 1px solid #aaa;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    cursor: pointer;
    background: none;
  }

  .swiper-button-next {
    right: 50px;
  }
  .swiper-button-prev {
    left: 50px;
  }
}

.view-search-boats.view-id-search_boats {
  .view-content {
    .views-row {
      .views-row-inner {
        .views-field {
          .boat-card {
            .book-boat-btn {
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.mobile-menu-bar {
  float: left;
  width: 100%;
  position: relative;
  background: #0c1923;
  min-height: 42px;
  z-index: 100;
  display: none;


  #mobile-menu-button {
    position: absolute;
    top: 11px;
    right: 20px;
    height: 22px;
    width: 22px;
    cursor: pointer;

    span {
      display: block;
      background: #fff;
      height: 3px;
      margin-top: 3px;
    }
  }

  .mobile-menu {

    padding-top: 42px;

    .main-menu {
      display: none;


      li {
        list-style: none;
        padding: 0;
        border-top: 1px solid rgba(255,255,255,.5);

        a {
          font-weight: 600;
          font-size: 13px;
          color: #fff;
          text-transform: uppercase;
          display: block;
          padding: 15px 0 15px 20px;
        }
        a:hover {
          color: #ff9900;
        }
      }
    }
  }

}

@media all and (min-width: 991px) {
  .front #content-top-second-wrapper:before {
    background: #fff url(/sites/all/themes/bob_theme/img/content-front-bg.jpg) 50% 0 no-repeat;
  }

  .view-view-full-width-for-boat-page .views-field-nothing {
    padding-top: 30vw;

    .booking-controls-container {

      float: right;

      .dates {
        width: 300px;
        display: inline-block;
      }

      .book-detail {
        display: inline-block;
      }

    }

  }

  .view-view-full-width-for-boat-page .views-field-field-boat-images img {
    height: 45vw;
  }

  .view-view-full-width-for-boat-page .views-field-nothing .col-md-9 {
    margin-top: -60px; /* bump the booking calendar up a bit to align with the title / owner name */
  }
}

.dates {
  label {
    color: white;
    text-shadow: #666 1px 1px 1px;
    margin-top: 10px;
    margin-bottom: 0;
  }
  input {
    padding: 5px 14px;
  }
  .form-item {
    position: relative;
    .fa {
      position: absolute;
      bottom: 11px;
      right: 27px;
      z-index: 10;
    }
  }
}

.access-denied {
  border: 4px solid;
  background: #eee;
  h1 {
    text-transform: uppercase;
    font-weight: bold;
    text-shadow: 0px 0px 2px #fff;
  }
  .info {
    margin: 20px 0;
  }
  a.ad-link {
    background: rgba(68, 68, 68, 0.5);
    padding: 5px 20px;
    border-radius: 10px;
    color: #fff;
  }
  a.ad-link:hover {
    background: rgba(68, 68, 68, 0.8);
  }
}

/* USER PROFILE PAGE */
 .card {
    margin-top: 20px;
    padding: 30px;
    background-color: rgba(214, 224, 226, 0.2);
    -webkit-border-top-left-radius:5px;
    -moz-border-top-left-radius:5px;
    border-top-left-radius:5px;
    -webkit-border-top-right-radius:5px;
    -moz-border-top-right-radius:5px;
    border-top-right-radius:5px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.card.hovercard {
    position: relative;
    padding-top: 0;
    overflow: hidden;
    text-align: center;
    background-color: #fff;
    background-color: rgba(255, 255, 255, 1);
    min-height: 155px;
}
.card.hovercard .card-background {
    height: 130px;
}
.card-background img {
    -webkit-filter: blur(25px);
    -moz-filter: blur(25px);
    -o-filter: blur(25px);
    -ms-filter: blur(25px);
    filter: blur(25px);
    margin-left: -100px;
    margin-top: -200px;
    min-width: 130%;
}
.card.hovercard .useravatar {
    position: absolute;
    top: 15px;
    left: 0;
    right: 0;
}
.card.hovercard .useravatar img {
    width: 100px;
    height: 100px;
    max-width: 100px;
    max-height: 100px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: 5px solid rgba(255, 255, 255, 0.5);
    margin: 0 auto;
}
.card.hovercard .card-info {
    position: absolute;
    bottom: 14px;
    left: 0;
    right: 0;
}
.card.hovercard .card-info .card-title {
    padding:0;
    font-size: 20px;
    line-height: 1;
    color: #262626;
    background-color: rgba(255, 255, 255, 0.1);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.card.hovercard .card-info {
    overflow: hidden;
    font-size: 12px;
    line-height: 20px;
    color: #737373;
    text-overflow: ellipsis;
}
.card.hovercard .bottom {
    padding: 0 20px;
    margin-bottom: 17px;
}
.btn-pref .btn {
    -webkit-border-radius:0 !important;
}

.boat-owner-profile .btn-primary, .boat-owner-profile .btn-primary:hover, .boat-owner-profile .btn-default:hover {
  background-color: #00bff3;
  border-color: #00bff3;
}

.boat-owner-profile .btn-default {
  background-color: #aaa;
  border-color: #aaa;
}

.boat-owner-profile .fivestar-widget-static, .boat-owner-profile .fivestar-widget {
  .star {
    width: 17px;
    height: 15px;
    float: none !important;
    display: inline-block !important;
  }
}

.boat-owner-profile div.fivestar-widget .star a {
    margin-right: 4px;
    width: 17px;
    height: 15px;
    margin: 0;
}

.boat-owner-profile div.fivestar-widget div.hover a {
  background-position: 0 -32px;
}

#feedback {

  .modal-header {
    padding: 5px 15px;
    background: #00bff3;
    color: #fff;

    .close {
      margin: 0;
    }

    .modal-title {
      padding: 0 25px;
    }
  }

  #comments h2.title {
    display: none;
  }

  .modal-dialog {
    max-width: 400px;
    margin-top: 5%;
  }

  .modal-content {
    border-radius: 0;
  }

  .field-name-comment-body {
    float: none;

    .grippie {
      display: none;
    }
  }

  #comments {
    margin: 0;
  }

  .form-type-fivestar.form-group {
    text-align: center;
    label {
      display: none;
    }
  }

  .title.comment-form, .text-format-wrapper fieldset.filter-wrapper, div[id*="edit-author"] {
    display: none;
  }

  #edit-actions {
    float: none;
    text-align: center;
  }

  div.fivestar-widget .cancel, div.fivestar-widget .star {
    display: inline-block;
    float: none;
  }

  div.fivestar-widget .star, div.fivestar-widget .star a div.fivestar-widget-static .star span.on, div.fivestar-widget-static .star, div.fivestar-widget-static .star span.off, div.fivestar-widget .star, div.fivestar-widget .star a, div.fivestar-widget-static .star span.on, div.fivestar-widget-static .star, div.fivestar-widget-static .star span.off {
    width: 22px;
    height: 22px;
    margin-right: 4px;
    background-size: cover;
  }

  div.fivestar-widget div.hover a {
    width: 22px;
    height: 22px;
    margin-right: 4px;
    background-size: cover;
  }

}

.node-type-boat {
  .comment-add {
    display: none;
  }
}

#views-exposed-form-search-boats-page {
  #open-map-widget {
    border-color: #00bff3;
    background-color: #00bff3;
    color: #fff;
    padding: 10px 12px;
    text-transform: initial;
    transition: none;
    margin: 0;
  }

  #open-map-widget:hover {
    background-color: #ff9900;
    border-color: #ff9900;
    color: #fff;
  }
}

.container_ {
  .filter-wrapper {
    display: none;
  }
}

input[type="radio"], input[type="checkbox"] {
  margin: 5px;
}

fieldset {
  .panel-heading {
    width: 100%;
    border: 1px solid;
    border-radius: 4px;
  }
}

.node-boat-form {
  .input-group {
    .form-file {
      padding: 9px 12px;
      height: auto;
    }
  }

  .boat-creation li.active a {
    font-weight: bold;
  }

  .horizontal-tabs-panes {
    background: #fff;
    border: 1px solid #ccc;
  }

  .horizontal-tabs {
    fieldset {
      margin-bottom: 0;
    }

    border: none;
  }

  .vertical-tabs {
    display: none;
  }
}

.horizontal-tabs ul.horizontal-tabs-list {
  display: inline-flex !important;
  border: none;
  background: transparent;
}

li.horizontal-tab-button {
  list-style: none !important;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  margin-right: 5px !important;
  border-radius: 4px 4px 0 0;
  padding: 0 !important;

  &.error {
    color: #900404 !important;
    border:1px solid !important;
    border-bottom: 0px !important;

    a {
      color: #900404 !important;
      background: rgba(255,0,0,0.1);

      strong {
        color: #900404 !important;
      }
    }
  }

  a {
    border-radius: 4px 4px 0 0;
  }
}

.full-profile-info-wrapper {
  margin-bottom: 10px;

  p {
    margin-bottom: 2px;
  }
}

.full-profile-info-title {
  color: #000;
  text-transform: uppercase;
  margin-top: 35px;
}

#views-exposed-form-search-boats-page {
  .form-type-date-popup {
    width: 100%;
    div {
      float: none;
      width: 100%;
    }

    input {
      width: 100%;
    }
  }

  #open-map-widget {
    width: 100%;
    padding: 7px 12px;
    margin: 6px 0 0 0;
  }

  .map-holder {
    position: relative;
    width: 10px;
    z-index: 99;
  }

  #map-widget-container {
    z-index: 99;
    @media screen and (max-width: 500px) {
      left: 15px;
    }

    @media screen and (max-width: 767px) {
      top: 67px;
    }
  }

  .form-item.form-item-field-boat-price-options-tid .form-group {
    margin: 0;
  }

  ul.bef-tree-child {
    padding-left: 15px;
  }

  button#edit-submit-search-boats {
    padding: 10px 50px;
  }
}

.page-node-edit.node-type-booking,
.page-node-add-booking {
  .field-name-field-boat-reference {
    display: none;
  }
}

.addtoany {
  clear: both;
  width: 100%;
  .addtoany_list {
    strong {
      font-size: 16px;
      vertical-align: text-top;
      line-height: 20px;
    }
  }
}

.margin-bottom-10 {
  margin: 0;
  margin-bottom: 10px;
}

label[for*="field-seasonal-from-und-0-value2"] {
    vertical-align: top;
    position: relative;
    top: 17px;
}

#views-exposed-form-search-boats-page h3 {
  border-top: 1px solid #00bff3;
  padding-top: 10px;
}

#block-block-31 .home-block-description {
  text-shadow: none;
}

#search-wrapper {
    background: linear-gradient(0deg,rgba(0,0,0,0.6), rgba(0,0,0,0.4)), url(/sites/all/themes/bob_theme/img/search-wrapper-bg.jpg) center center no-repeat;
    color: #fff;
    background-size: cover;
}

.search-boat-image img {
  width: 100%;
  min-height: 150px;
}

.search-boat-attr p {
  margin: 0;
}

.search-boat-book .book-boat-btn {
  color: #fff;
  width: 100%;
  @media only screen and (max-width: 767px) and (orientation: portrait) {}
}

.search-boat-info .title a {
    font-size: 130%;
    font-weight: bold;
    color: #000;
}

.view-search-boats .views-row {
  @media only screen and (max-width: 767px) and (orientation: landscape) {
    max-width: 400px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}

.view-view-full-width-for-boat-page .views-field-nothing .book-detail .field-book-boat {
  a {
    padding: 0px 25px 0px 0;
    i {
      margin-right: 5px;
    }
  }
}

.boat-detail {
  .block-title {
    margin-bottom: 0;
  }

  .price {
    margin: 0 !important;
  }

  .tab-content {
    /*border: 1px solid #00bff3;*/
    padding: 5px 0;
    margin-bottom: 30px;
  }

  .nav-pills {
    a[data-toggle="pill"] {
      border-radius: 0 !important;
      background: #ddd;
    }

    li {
      @media only screen and (max-width: 767px) and (orientation: portrait) {
        margin: 0 !important;
        width: 100%;
        border-bottom: 1px solid #fff;
      }
    }

    li.active a[data-toggle="pill"] {
      background: #00bff3;
    }

  }

  .field-name-field-boat-price-options {
    .field-items .field-item {
        display: inline-block;
        padding: 2px 20px;
        background: #ddd;
        margin-right: 4px;
    }

    .field-label {
      padding-bottom: 10px;
      color: #2B2B2B;
      font-size: 20px;
      text-transform: uppercase;
      position: relative;
      font-weight: 500;
      margin-top: 20px;
    }
  }

  .field-name-field-seasonal-prices, .field-name-field-boat-rating {
     > .field-label {
      padding-bottom: 10px;
      color: #2B2B2B;
      font-size: 20px;
      text-transform: uppercase;
      position: relative;
      font-weight: 500;
      margin-top: 20px;
    }
  }

  span.spoken-langs {
      padding: 3px 10px;
      background: #ddd;
  }

  .price1 {
    color: #286090;
  }

  .price2 {
    color: green;
  }

  .swiper-slide.boat-image img {
    /*width: 100%;*/
    height: auto;
  }

}

.region-sidebar-first .block-menu ul.menu li {
  padding: 0;
}

.front section#sidebar-first {
    /*padding: 90px 30px;*/
}

.front div#page {
  background: #fff;
}

.front div#content-top-second-wrapper {
  padding: 30px 0 120px;
}

.no-hpadding {
  padding-left: 0;
  padding-right: 0;
}

.front .region-sidebar-first.well {
      padding: 40px 30px;
}

.front #content-top-second-wrapper:before {
  background-size: cover;
  right: -15px;
  left: initial;
}

.info_popup {
  font-size: 12px;
  display: inline-block;
  vertical-align: middle;
}


/*

====== Zoom effect ======

*/
.mfp-zoom-in {

  /* start state */
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.2s ease-in-out;
    transform: scale(0.8);
  }

  &.mfp-bg {
    opacity: 0;
	  transition: all 0.3s ease-out;
  }

  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: scale(1);
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }

  /* animate out */
  &.mfp-removing {

    .mfp-with-anim {
      transform: scale(0.8);
      opacity: 0;
    }
    &.mfp-bg {
      opacity: 0;
    }

  }

}











/* my additions */

#map-widget {
  width: 100%;
  height: 300px;
}

#edit-field-coordinates-distance-field {
  display: none;
}

/* hide lat/lnt input fields from view while still showing their "fill this field" bubble if they are empty.
   cna't use the visibility or display property, because that would hide the bubble too. */
.vex-dialog-form .availability-edit-lat .vex-custom-input-wrapper,
.vex-dialog-form .availability-edit-lng .vex-custom-input-wrapper {
  height: 0;
}
.vex-dialog-form .availability-edit-lat .vex-custom-input-wrapper input,
.vex-dialog-form .availability-edit-lng .vex-custom-input-wrapper input {
  background-color: transparent !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: 0 !important;
}

body:not(.page-node-edit).node-type-page .region-content .field-type-file {
  text-align: right;
  padding-bottom: 20px;
}

.attributions {
  font-size: 8px;
}

.link-to-manual {
  padding-top: 20px;
  clear: both;
  text-align: center;
}

.link-to-manual span {
  font-size: 18px;
}

.register-button-container {
  margin-top: 20px;
}

.block-custom-bizreview-featured .col-md-3 .rtecenter {
  margin-bottom: 5px;
}

.block-custom-bizreview-featured .col-md-3 .rtecenter .normal-text,
.block-custom-bizreview-featured .col-md-3 .rtecenter .normal-text:hover {
  color: #666;
}

@media (max-width: 499px) {
  .block-custom-bizreview-featured .col-md-3 {
    width: 100%;
    min-height: 110px;
    float: left;
  }
}

@media (min-width: 500px) and (max-width: 991px) {
  .block-custom-bizreview-featured .col-md-3 {
    width: 50%;
    min-height: 160px;
    float: left;
  }
}

@media (min-width: 992px) {
  .block-custom-bizreview-featured .col-md-3 {
    width: 25%;
    min-height: 190px;
    float: left;
  }
}

/* for info buttons popup */
.info-button-popup {
  position: relative;
  background: #FFF;
  padding: 25px;
  width:auto;
  max-width: 400px;
  margin: 0 auto;
}

/* overwriting parent theme */
#content-top-first-wrapper {
  padding: 0 !important;
}

/* make sure map popup in front page header search stays on top of everything, even if Backstretch tries to put the whole section content into the background */
#search-wrapper {
  z-index: 1 !important;
}

/* make sure no columns are cut off */
.view-my-bookings .table-responsive {
  overflow-x: visible;
}

/* hide breadcrumb during booking */
.page-node-add-booking #breadcrumb {
  display: none;
}
